@import 'breakpoints';
@import 'variables';

.footer {
  position: relative;

  &__container {
    @extend .center;
    padding: 50px 16px;

    @include breakpoint(phablet) {
    }

    @include breakpoint(tablet) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
  }

  &__imgs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    img {
      margin: 0 32px 32px 32px;

      @include breakpoint(tablet) {
        margin: 0 32px 0 0;
      }
    }
  }

  &__flex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include breakpoint(tablet) {
      flex-direction: row;
    }

    a {
      margin-top: 32px;

      @include breakpoint(tablet) {
        margin: 0 0 0 32px;
      }
    }
  }

  &__wrapper {
    display: grid;
    gap: 30px;
    position: relative;
    z-index: 1;

    @include breakpoint(phablet) {
      grid-template-columns: 250px 250px;
      justify-content: center;
    }

    @include breakpoint(tablet) {
      grid-template-columns: 270px 270px;
    }
  }

  &__text {
    @extend .text;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 60px;
      height: 4px;
      background: #5a7184;
      opacity: 0.1;
      border-radius: 2px;
      position: absolute;
      top: -24px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
