.report {
  background-color: #f7f9ff;

  &__container {
    @extend .center;
    padding: 50px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @include breakpoint(phablet) {
      padding: 70px 16px;
      flex-direction: row;
      align-items: center;
    }

    @include breakpoint(tablet) {
      padding: 90px 16px;
    }

    @include breakpoint(laptop) {
      padding: 110px 16px;
    }

    @include breakpoint(desktop) {
      padding: 130px 16px;
    }

    h6 {
      @extend .title;
      margin-bottom: 32px;
    }

    p {
      @extend .text;
      max-width: 600px;
    }

    &__svg {
      width: 150px;
      height: 150px;
      margin-top: 32px;

      @include breakpoint(phablet) {
        margin: 0 0 0 32px;
        width: 200px;
        height: 200px;
      }

      @include breakpoint(tablet) {
        width: 250px;
        height: 250px;
      }

      @include breakpoint(laptop) {
        width: 300px;
        height: 300px;
      }

      @include breakpoint(desktop) {
        height: auto;
        width: auto;
      }
    }
  }

  &__box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include breakpoint(tablet) {
      flex-direction: row;
    }

    h5 {
      margin: 0;
      color: #22bb33;
    }

    svg {
      fill: #22bb33;

      @include breakpoint(tablet) {
        margin-right: 16px;
      }
    }
  }

  &__input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 32px 0;
    max-width: 450px;

    input {
      padding: 16px;
      border-radius: 6px;
      border: 1px solid #e6eaed;
      width: 68%;
      height: 61px;
      box-sizing: border-box;
    }

    button {
      height: 61px;
    }
  }

  label {
    @extend .text;
    font-size: 14px;
    line-height: 24px;
  }
}
