@import "variables";
@import "breakpoints";

.hero {
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 75px 16px 75px;
  @extend .center;

  @include breakpoint(tablet) {
    padding: 0 16px;
  }

  img {
    width: 100%;
    height: 100%;
  }

  &__container {
    width: 100%;

    @include breakpoint(tablet) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__wrapper {
    @include breakpoint(tablet) {
      max-width: 475px;
    }

    @include breakpoint(laptop) {
      margin-top: 80px;
    }

    @include breakpoint(desktop) {
      margin-top: 100px;
    }
  }

  &__title {
    @extend .title;

    font-size: 48px;
    line-height: 56px;
    margin-bottom: 30px;
  }

  &__text {
    @extend .text;

    margin-bottom: 30px;

    @include breakpoint(tablet) {
      margin-bottom: 60px;
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;

    a {
      margin: 10px 10px 0 0;
    }
  }

  &__partner {
    max-width: 118px;
    max-height: 30px;
    margin: 0 20px 20px 0;

    @include breakpoint(phablet) {
      margin: 0 40px 40px 0;
    }

    @include breakpoint(tablet) {
      margin-bottom: 50px;
    }

    @include breakpoint(laptop) {
      margin-bottom: 70px;
    }
  }

  &__twojPsycholog {
    max-width: 211px;
    max-height: 30px;
    margin-bottom: 20px;

    @include breakpoint(phablet) {
      margin-bottom: 40px;
    }

    @include breakpoint(tablet) {
      margin-bottom: 50px;
    }

    @include breakpoint(laptop) {
      margin-bottom: 70px;
    }
  }

  &__image {
    display: block;
    max-width: 500px;
    margin: auto;
    transition: 300ms max-width;

    @include breakpoint(tablet) {
      max-width: 380px;
    }

    @include breakpoint(laptop) {
      max-width: 480px;
    }

    @include breakpoint(desktop) {
      max-width: 684px;
      max-height: 684px;
    }
  }
}
