@font-face {
  font-family: HkGroteskBold;
  src: url(/public/fonts/HKGrotesk-Bold.otf);
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: OpenSansBold;
  src: url(/public/fonts/OpenSans-Bold.ttf);
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: OpenSansRegular;
  src: url(/public/fonts/OpenSans-Regular.ttf);
  font-weight: 400;
  font-display: swap;
}
