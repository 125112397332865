@import 'variables';
@import 'breakpoints';

.title {
  font-family: HkGroteskBold, sans-serif;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: 0.2px;
  color: $black-color;

  @include breakpoint(tablet) {
    font-size: 36px;
    line-height: 48px;
  }

  @include breakpoint(laptop) {
    font-size: 46px;
    line-height: 58px;
  }

  @include breakpoint(desktop) {
    font-size: 56px;
    line-height: 68px;
  }
}

.sub-title {
  font-family: HkGroteskBold, sans-serif;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: 0.2px;
  color: $black-color;
  margin-bottom: 50px;

  @include breakpoint(phablet) {
    font-size: 30px;
    line-height: 42px;
    text-align: center;
    margin-bottom: 60px;
  }

  @include breakpoint(tablet) {
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 70px;
  }

  @include breakpoint(laptop) {
    font-size: 34px;
    line-height: 46px;
    margin-bottom: 80px;
  }

  @include breakpoint(desktop) {
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 90px;
  }
}

.text {
  font-size: 16px;
  line-height: 30px;
  color: $gray-color;

  @include breakpoint(phablet) {
    font-size: 17px;
    line-height: 31px;
  }

  @include breakpoint(tablet) {
    font-size: 18px;
    line-height: 32px;
  }
}

.button {
  background: $purple-color;
  border-radius: 8px;
  color: $white-color;
  font-family: OpenSansBold, sans-serif;
  padding: 13px 24px;
  margin: 0 10px 0 0;
  border: none;
  cursor: pointer;
  transition: 300ms background-color;
  font-size: 13px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-decoration: none;

  &:hover {
    background-color: darken($purple-color, 20%);
  }
}

.button-alternative {
  background: #3fa09a;
  border-radius: 8px;
  color: $white-color;
  font-family: OpenSansBold, sans-serif;
  padding: 13px 24px;
  border: none;
  cursor: pointer;
  -webkit-transition: 300ms background-color;
  transition: 300ms background-color;
  font-size: 13px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-decoration: none;

  &:hover {
    background-color: #217771;
  }
}

.link-disabled {
  color: $gray-color !important;
  text-decoration-line: none !important;
}

.background-oval {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('/public/images/Oval.webp') no-repeat;
  z-index: -1;
}

.center {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 16px;
}
