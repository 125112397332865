@import 'breakpoints';
@import 'variables';

.content {
  background-color: #f7f9ff;
  position: relative;

  &__container {
    @extend .center;
    padding: 25px 16px;
    position: relative;
    z-index: 1;
  }

  &__clip {
    position: absolute;
    top: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: ellipse(80% 2%);
    background-color: #f7f9ff;
    z-index: -1;

    @include breakpoint(phablet) {
      clip-path: ellipse(75% 5%);
    }

    @include breakpoint(tablet) {
      clip-path: ellipse(70% 5%);
    }

    @include breakpoint(laptop) {
      clip-path: ellipse(65% 5%);
    }

    @include breakpoint(desktop) {
      clip-path: ellipse(60% 5%);
    }
  }

  &__title {
    @extend .title;
    margin-bottom: 40px;

    @include breakpoint(phablet) {
      text-align: center;
      margin-bottom: 50px;
    }

    @include breakpoint(tablet) {
      margin-bottom: 60px;
    }

    @include breakpoint(laptop) {
      margin-bottom: 70px;
    }

    @include breakpoint(desktop) {
      margin-bottom: 80px;
    }
  }

  &__wrapper {
    display: grid;
    max-width: 1100px;
    margin: 0 auto 40px;
    align-items: center;

    @include breakpoint(tablet) {
      grid-template-columns: 350px 1fr;
      justify-content: space-between;
      margin-bottom: 60px;
    }

    @include breakpoint(laptop) {
      grid-template-columns: 455px 1fr;
      margin-bottom: 80px;
    }

    @include breakpoint(desktop) {
      margin-bottom: 100px;
    }

    &--reverse {
      display: flex;
      flex-direction: column-reverse;

      @include breakpoint(tablet) {
        display: grid;
        grid-template-columns: 1fr 350px;
      }

      @include breakpoint(laptop) {
        grid-template-columns: 516px 455px;
      }
    }

    img {
      width: 100%;
      max-width: 500px;
      margin: auto;
    }

    h3 {
      font-family: HkGroteskBold, sans-serif;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.2px;
      color: $black-color;
      margin-bottom: 16px;

      @include breakpoint(phablet) {
        font-size: 26px;
        line-height: 34px;
        margin-bottom: 18px;
      }

      @include breakpoint(tablet) {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 20px;
      }

      @include breakpoint(laptop) {
        font-size: 30px;
        line-height: 38px;
        margin-bottom: 22px;
      }

      @include breakpoint(desktop) {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 24px;
      }
    }

    p {
      @extend .text;
      margin-bottom: 24px;

      @include breakpoint(phablet) {
        margin-bottom: 26px;
      }

      @include breakpoint(tablet) {
        margin-bottom: 28px;
      }

      @include breakpoint(laptop) {
        margin-bottom: 30px;
      }

      @include breakpoint(desktop) {
        margin-bottom: 32px;
      }
    }

    span {
      font-family: OpenSansBold, sans-serif;
      font-size: 16px;
      line-height: 22px;
      text-decoration-line: underline;
      color: $purple-color;
      display: block;
      margin-bottom: 16px;
      max-width: 380px;

      a {
        -webkit-appearance: button;
        -moz-appearance: button;
        text-decoration: none;
        color: $purple-color;
        font-family: OpenSansBold, sans-serif;
      }

      @include breakpoint(phablet) {
        margin-bottom: 18px;
      }

      @include breakpoint(tablet) {
        margin-bottom: 20px;
      }

      @include breakpoint(laptop) {
        margin-bottom: 22px;
      }

      @include breakpoint(desktop) {
        margin-bottom: 24px;
      }
    }
  }
}
